<template>
  <div class="app-install-slider">
    <img src="@/assets/icons/svg/lugstay-logo.svg" alt="" />
    <h1 class="header">{{ $__t("앱 설치하고 카메라로 간편 체크인 하세요") }}!</h1>
    <p
      class="explanatory"
      v-html="
        $__t(
          `In case you booked through the web page, you will need to re-access the mobile page for check-in/check-out.`
        )
      "
    ></p>
    <div v-if="loading" class="loading">
      <lottie :options="lottieOptions" :height="100" :width="100"></lottie>
    </div>
    <ul class="buttons">
      <li>
        <a :href="installLink" target="_blank">
          <button @click="onClickNoSlider" class="install_app_button">
            {{ $__t("앱 설치하기") }}
          </button>
        </a>
      </li>
      <li>
        <button class="mobile_web_button" @click="onClickCancel">
          {{ $__t("모바일 웹으로 볼게요") }}
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import loadImage from "blueimp-load-image";
import Lottie from "vue-lottie";
import LottieLoading from "@/assets/lottie/loading-primary.json";

export default {
  props: ["ticket"],

  data() {
    return {
      loading: false,
      lottieOptions: {
        animationData: LottieLoading,
      },

      file: null,
      resource: null,
      imagePreview: "",
      installLink: this.wni.isAndroid
        ? "https://bit.ly/럭스테이플레이스토어"
        : "https://bit.ly/럭스테이앱스토어",
    };
  },

  methods: {
    onClickCancel() {
      this.$emit("cancel");
      this.$emit("close");
      // window.sessionStorage.setItem('noSlider', true);
      this.$store.commit("layout/CLOSE_SLIDER");
    },
    onClickNoSlider() {
      // window.sessionStorage.setItem('noSlider', true);
      this.$store.commit("layout/CLOSE_SLIDER");
    },
  },

  components: {
    Lottie,
  },
};
</script>

<style scoped lang="scss">
.app-install-slider {
  background: $color-white;
  padding: unit(10);
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: unit(42);
    height: unit(42);
  }

  .header {
    margin-top: unit(24);
    font-size: unit(20);
    font-weight: 600;
    margin-bottom: unit(14);
  }

  .explanatory::v-deep {
    text-align: center;
    margin-top: unit(4);
    font-size: unit(16);
    // line-height: 1.43;
    line-height: unit(24);
    letter-spacing: -0.006em;
    color: #61676c;

    strong,
    b {
      font-size: unit(16);
      font-weight: bold;
    }

    b {
      color: $color-red;
    }
  }

  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .buttons {
    margin-top: unit(40);
    button {
      font-size: unit(14);
      width: unit(335);
      height: unit(50);
      border-radius: unit(24);
      &.install_app_button {
        background-color: #48d9eb;
        color: #ffffff;
      }
      &.mobile_web_button {
        background-color: #ffffff;
        color: #878d91;
        text-decoration: underline;
      }
    }
  }

  .actions {
    margin-top: unit(50);
    display: flex;
    gap: unit(10);

    .btn {
      flex: 1;
      font-size: unit(14);
      line-height: 1.43;
      padding: unit(15) unit(16);
      text-align: center;

      &--cancel {
        color: #a9afb3;
        background: $color-white;
        border: solid 1px #e1e4e6;
        border-radius: unit(10);
      }

      &--confirm {
        color: $color-white;
        background: #48d9eb;
        border-radius: unit(10);
      }

      &--disabled {
        cursor: not-allowed;
        pointer-events: none;
      }
    }

    .input {
      position: absolute;
      width: 0;
      height: 0;
      padding: 0;
      overflow: hidden;
      border: 0;
    }
  }
}
</style>
