<template>
  <div class="page" id="default-landing-page" @click="isShowPopupmenu = false">
    <div class="section" id="home-search-section">
      <div class="container">
        <div class="body">
          <div class="title">
            <span class="block" :class="{ se: $store.state.lang !== 'ko' }">{{ $__t('Store Renting Spaces') }}</span>
            {{ $__t('Luggage Storage Service') }}
            <div class="name">
              {{ $__t('LugStay') }}
            </div>
            <div class="subtitle">
              <p>
                {{ $__t('Keep your all kinds of items anytime, anywhere in local stores on your mobile phone.') }}
              </p>
            </div>
          </div>

          <div class="search-engine mobile">
            <div class="button-area">
              <button type="button" class="search-button" @click="onClickSearchButton">
                {{ $__t('{home}.search-section.button') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- home-video-section -->

    <div class="section" id="home-necessary-moment-section">
      <div class="container">
        <div class="section-header">
          <div class="title">
            <div class="secondary">NECESSARY MOMENT</div>
            <div class="primary">
              {{ $__t('The moments you need LugStay') | capitalizeFirstLetter }}
            </div>
          </div>
        </div>

        <div class="section-body pc">
          <div class="row">
            <div class="container">
              <div class="image">
                <img src="/img/moment-shopping.png" />
              </div>
              <div class="text">
                {{ $__t('shopping items become hassle') | capitalizeFirstLetter }}
              </div>
            </div>
            <div class="container">
              <div class="image">
                <img src="/img/moment-travel.png" />
              </div>
              <div class="text">
                {{ $__t('feel liberated while traveling') | capitalizeFirstLetter }}
              </div>
            </div>
            <div class="container">
              <div class="image">
                <img src="/img/moment-gift.png" />
              </div>
              <div class="text">
                {{ $__t('give a precious thing to loved one') | capitalizeFirstLetter }}
              </div>
            </div>
            <div class="container">
              <div class="image">
                <img src="/img/moment-delivery.png" />
              </div>
              <div class="text">
                {{ $__t('receive a delivery safe') | capitalizeFirstLetter }}
              </div>
            </div>
          </div>
          <div class="row center">
            <div class="container">
              <div class="image">
                <img src="/img/moment-wayback.png" />
              </div>
              <div class="text">
                {{ $__t('plans to do on your way back home') | capitalizeFirstLetter }}
              </div>
            </div>
            <div class="container">
              <div class="image">
                <img src="/img/moment-large.png" />
              </div>
              <div class="text">
                {{ $__t('be released from big items') | capitalizeFirstLetter }}
              </div>
            </div>
            <div class="container">
              <div class="image">
                <img src="/img/moment-secondhand.png" />
              </div>
              <div class="text">
                {{ $__t('safe second-hand deal') | capitalizeFirstLetter }}
              </div>
            </div>
          </div>
        </div>

        <div class="section-body mobile">
          <div class="carousel">
            <lug-carousel slideType="slide" :arrow="true" :pager="true" :zoomable="true">
              <lug-carousel-item>
                <div class="body">
                  <div class="row">
                    <div class="container">
                      <div class="image">
                        <img src="/img/moment-shopping.png" />
                      </div>
                      <div class="text">
                        {{ $__t('shopping items become hassle') | capitalizeFirstLetter }}
                      </div>
                    </div>
                    <div class="container">
                      <div class="image">
                        <img src="/img/moment-travel.png" />
                      </div>
                      <div class="text">
                        {{ $__t('feel liberated while traveling') | capitalizeFirstLetter }}
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="container">
                      <div class="image">
                        <img src="/img/moment-gift.png" />
                      </div>
                      <div class="text">
                        {{ $__t('give a precious thing to loved one') | capitalizeFirstLetter }}
                      </div>
                    </div>
                    <div class="container">
                      <div class="image">
                        <img src="/img/moment-delivery.png" />
                      </div>
                      <div class="text">
                        {{ $__t('receive a delivery safe') | capitalizeFirstLetter }}
                      </div>
                    </div>
                  </div>
                </div>
              </lug-carousel-item>
              <lug-carousel-item>
                <div class="body">
                  <div class="row">
                    <div class="container">
                      <div class="image">
                        <img src="/img/moment-wayback.png" />
                      </div>
                      <div class="text">
                        {{ $__t('plans to do on your way back home') | capitalizeFirstLetter }}
                      </div>
                    </div>
                    <div class="container">
                      <div class="image">
                        <img src="/img/moment-large.png" />
                      </div>
                      <div class="text">
                        {{ $__t('be released from big items') | capitalizeFirstLetter }}
                      </div>
                    </div>
                  </div>
                  <div class="row center">
                    <div class="container">
                      <div class="image">
                        <img src="/img/moment-secondhand.png" />
                      </div>
                      <div class="text">
                        {{ $__t('safe second-hand deal') | capitalizeFirstLetter }}
                      </div>
                    </div>
                  </div>
                </div>
              </lug-carousel-item>
            </lug-carousel>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="section" id="home-guide-section">
      <div class="container">
        <div class="section-header">
          <div class="title">
            <div class="primary">{{ $__t('어떻게 사용하나요?') }}</div>
          </div>
        </div>
        <div class="section-body pc">
          <user-guide :index="0" />
          <user-guide :index="1" />
        </div>
        <div class="section-body mobile">
          <div class="carousel">
            <lug-carousel slideType="slide" :arrow="true" :pager="true" :zoomable="true">
              <lug-carousel-item>
                <div id="user-guide">
                  <div class="container">
                    <div class="title">
                      <div class="text">
                        {{ `1. ${this.$__t('Drop Off')}` }}
                      </div>
                    </div>
                    <div class="section">
                      <div class="textarea">
                        <div class="step">Step 1</div>
                        <div class="description">
                          {{
                            this.$__t(
                              'Go to the store to drop off your item and show your QR ticket to the host and check-in'
                            )
                          }}
                        </div>
                      </div>
                      <div class="image">
                        <img src="/img/checkin-step1-1.png" alt="`이용방법-Step 1-0`" />
                        <div class="caption">
                          {{ this.$__t('Search a store to keep your item and make a reservation.') }}
                        </div>
                      </div>
                      <div class="image">
                        <img src="/img/checkin-step1-2.png" alt="`이용방법-Step 1-1`" />
                        <div class="caption">
                          {{
                            this.$__t(
                              'Go to the store you made a reservation and let host scan your QR ticket and proceed check-in.'
                            )
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </lug-carousel-item>
              <lug-carousel-item>
                <div id="user-guide">
                  <div class="container">
                    <div class="title">
                      <div class="text">
                        {{ `1. ${this.$__t('Drop Off')}` }}
                      </div>
                    </div>
                    <div class="section">
                      <div class="textarea">
                        <div class="step">Step 2</div>
                        <div class="description">
                          {{ this.$__t('Host will keep your item secured') }}
                        </div>
                      </div>
                      <div class="image">
                        <img src="/img/checkin-step2-1.png" alt="`이용방법-Step 2-0`" />
                        <div class="caption">
                          {{ this.$__t('Host will take a photo of the item.') }}
                        </div>
                      </div>
                      <div class="image">
                        <img src="/img/checkin-step2-2.png" alt="`이용방법-Step 2-1`" />
                        <div class="caption">
                          {{ this.$__t('Your item is kept secured.') }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </lug-carousel-item>
              <lug-carousel-item>
                <div id="user-guide">
                  <div class="container">
                    <div class="title">
                      <div class="text">
                        {{ `2. ${this.$__t('Pick Up')}` }}
                      </div>
                    </div>
                    <div class="section">
                      <div class="textarea">
                        <div class="step">Step 1</div>
                        <div class="description">
                          {{
                            this.$__t(
                              'Go to the store you dropped off your item and show host the item image in QR ticket and pick up'
                            )
                          }}
                        </div>
                      </div>
                      <div class="image">
                        <img src="/img/checkout-step1-1.png" alt="`이용방법-Step 1-0`" />
                      </div>
                    </div>
                    <div class="section">
                      <div class="textarea">
                        <div class="step">Step 2</div>
                        <div class="description">
                          {{ this.$__t('Push the STOP button.') }}
                        </div>
                        <div class="detail">
                          {{ this.$__t('LugStay service is charged real-time.') }}<br />
                          {{ this.$__t('Please push the STOP button to avoid being overcharged.') }}
                        </div>
                      </div>
                      <div class="image">
                        <img src="/img/checkout-step2-1.png" alt="`이용방법-Step 2-0`" />
                      </div>
                    </div>
                  </div>
                </div>
              </lug-carousel-item>
            </lug-carousel>
          </div>
        </div>
      </div>
    </div> -->
    <!-- home-guide-section -->

    <!-- <div class="section" id="home-price-section">
      <div class="container">
        <div class="section-header">
          <div class="title">
            <div class="primary" v-html="priceText"></div>
          </div>
        </div>

        <div class="body">
          <ul>
            <li>
              <div class="table">
                <div class="table-header">
                  <span class="text">Small</span>
                </div>
                <div class="table-body">
                  <div class="table-cell left">
                    <div class="wrapper">
                      <div class="basic">
                        {{ $__t('10 minutes_p') }}
                      </div>
                      <div class="price"><strong>100</strong>{{ $__t('won') }}</div>
                    </div>
                  </div>
                  <div class="table-cell right">
                    <div class="wrapper">
                      <img src="/img/item-backpack-small.png" />
                      <img src="/img/item-shoppingbag.png" />
                    </div>
                    <div class="wrapper">
                      <img src="/img/item-parcel.png" />
                      <img src="/img/item-purse.png" />
                    </div>
                  </div>
                </div>
                <div class="table-footer">
                  <span
                    >{{ $__t('basic fee') | capitalizeFirstLetter }} 2,000{{ $__t('won') }} / {{ $__t('max per day') }} 6,000{{ $__t('won') }}</span
                  >
                </div>
              </div>
            </li>
            <li>
              <div class="table">
                <div class="table-header">
                  <span class="text">Large</span>
                </div>
                <div class="table-body">
                  <div class="table-cell left">
                    <div class="wrapper">
                      <div class="basic">
                        {{ $__t('10 minutes_p') }}
                      </div>
                      <div class="price"><strong>150</strong>{{ $__t('won') }}</div>
                    </div>
                  </div>
                  <div class="table-cell right">
                    <div class="wrapper">
                      <img src="/img/item-suitcase.png" />
                      <img src="/img/item-backpack-big.png" />
                    </div>
                    <div class="wrapper">
                      <img src="/img/item-scooter.png" />
                      <img src="/img/item-stroller.png" />
                    </div>
                  </div>
                </div>
                <div class="table-footer">
                  <span
                    >{{ $__t('basic fee') | capitalizeFirstLetter }} 3,000{{ $__t('won') }} / {{ $__t('max per day') }} 9,000{{ $__t('won') }}</span
                  >
                </div>
              </div>
            </li>
          </ul>
          <p>
            <span @click="openModal('priceGuideModal')"> {{ $__t('More details of the price') }}<img src="/img/icon-question-home.png" /> </span>
          </p>
        </div>
      </div>
    </div> -->

    <modal
      id="login-modal"
      ref="login-modal"
      submitButtonLabel="전화번호 인증하기"
      @submit="
        closeRef('login-modal');
        onClickGoLoginPage();
      "
    >
      <template v-slot:body>
        <div class="text">
          <div class="title">로그인 방식 변경</div>
          <div class="context">
            럭스테이 로그인이<br />
            <span class="strong">전화번호 로그인으로 변경</span>되었습니다.
            <br />
            <br />
            기존 고객님의 경우 계정 동기화를 위해<br />
            <span class="strong">전화번호 인증</span>을 진행해 주세요 :)
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div>
          <button>전화번호 인증하기</button>
        </div>
      </template>
    </modal>

    <!-- home-price-section -->
    <div class="section" id="home-price-section">
      <div class="container">
        <div class="section-header">
          <div class="title">
            <div class="primary" v-html="priceText"></div>
          </div>
        </div>

        <div class="body">
          <ul>
            <!-- <li>
              <div class="table">
                <div class="table-header">
                  <span class="text">Small</span>
                </div>
                <div class="table-body">
                  <div class="table-cell left">
                    <div class="wrapper">
                      <div class="basic">
                        {{ $__t('10 minutes_p') }}
                      </div>
                      <div class="price"><strong>100</strong>{{ $__t('won') }}</div>
                    </div>
                  </div>
                  <div class="table-cell right">
                    <div class="wrapper">
                      <img src="/img/item-backpack-small.png" />
                      <img src="/img/item-shoppingbag.png" />
                    </div>
                    <div class="wrapper">
                      <img src="/img/item-parcel.png" />
                      <img src="/img/item-purse.png" />
                    </div>
                  </div>
                </div>
                <div class="table-footer">
                  <span
                    >{{ $__t('basic fee') | capitalizeFirstLetter }} 2,000{{ $__t('won') }} / {{ $__t('max per day') }} 6,000{{ $__t('won') }}</span
                  >
                </div>
              </div>
            </li> -->
            <li>
              <div class="table">
                <div class="table-header">
                  <span class="text">Price</span>
                </div>
                <div class="table-body">
                  <div class="table-cell left">
                    <div class="wrapper">
                      <div class="basic">
                        {{ $__t('10 minutes_p') }}
                      </div>
                      <div class="price"><strong>150</strong>{{ $__t('won') }}</div>
                    </div>
                  </div>
                  <div class="table-cell right">
                    <div class="wrapper">
                      <img src="/img/item-suitcase.png" />
                      <img src="/img/item-backpack-big.png" />
                    </div>
                    <div class="wrapper">
                      <img src="/img/item-scooter.png" />
                      <!-- <img src="/img/item-stroller.png" /> -->
                      <img src="/img/item-parcel.png" />
                    </div>
                  </div>
                </div>
                <div class="table-footer">
                  <span
                    >{{ $__t('basic fee') | capitalizeFirstLetter }} 2,500{{ $__t('won') }} / {{ $__t('max per day') }} 8,500{{ $__t('won') }}</span
                  >
                </div>
              </div>
            </li>
          </ul>
          <p>
            <span @click="openModal('priceGuideModal')"> {{ $__t('More details of the price') }}<img src="/img/icon-question-home.png" /> </span>
          </p>
        </div>
      </div>
    </div>

    <!-- home-review-section -->
    <div class="section" id="home-review-section">
      <div class="container">
        <div class="section-header">
          <div class="title">
            <div class="primary">{{ $__t('{home-review-section}.title') }}</div>
          </div>
        </div>

        <div class="section-body">
          <div class="carousel">
            <CarouselCard :autoplay="false" :autosize="true" type="card" arrow="always" indicatorPosition="none">
              <CarouselCardItem v-for="(src, i) in getReviewImageByViewport" :key="i">
                <img :src="src" />
              </CarouselCardItem>
            </CarouselCard>
          </div>

          <ui-solid-button color="primary" size="medium" @click="onClickStoresMiddle">
            <button type="button">{{ $__t('go keep my item') }}</button>
          </ui-solid-button>
        </div>
      </div>
    </div>

    <!-- home-about-section -->
    <div class="section" id="home-about-section">
      <div class="container">
        <div class="section-header">
          <div class="title">
            <div class="primary" style="font-size: 1.9rem">
              {{ $__t('Safe & Easy') }}
            </div>
          </div>
        </div>

        <div class="section-body">
          <ul>
            <li>
              <div class="image">
                <img src="/img/home-about-03.png" />
              </div>
              <div class="text">
                <div class="primary">{{ $__t('쉽고 편한 사용성') }}</div>
                <div class="secondary" style="word-break: break-all">
                  {{ $__t('QR 코드로 물품 보관 및 찾기가 간편합니다.') }}
                </div>
              </div>
            </li>
            <li>
              <div class="image">
                <img src="/img/home-about-02.png" />
              </div>
              <div class="text">
                <div class="primary">{{ $__t('안전한 보관') }}</div>
                <div class="secondary" style="word-break: break-all">
                  {{ $__t('CCTV의 별도의 보관 공간이 있는 호스트만 선별했습니다.') }}
                </div>
              </div>
            </li>
            <li>
              <div class="image">
                <img src="/img/card-blue.png" />
              </div>
              <div class="text">
                <div class="primary">{{ $__t('Simple Payment') }}</div>
                <div class="secondary" style="word-break: break-all">
                  {{ $__t('Register your card and pay as much as you used.') }}
                </div>
              </div>
            </li>
            <li>
              <div class="image">
                <img src="/img/insure-onehundred.svg" />
              </div>
              <div class="text">
                <div class="primary">{{ $__t('분실 파손 보험') }}</div>
                <div class="secondary" style="word-break: break-all">
                  {{ $__t('분실 혹은 손상 발견 시 최대 100만원 보상해드립니다.') }}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- home-app-download-section -->
    <div v-if="!wni.isNative" class="section" id="home-app-download-section">
      <div class="container">
        <div class="section-header">
          <div class="title">
            <div class="primary" v-html="$__t('{home}.app-download-section.header')" />
          </div>
        </div>

        <div class="section-body">
          <div class="container">
            <div class="image-area" />
            <div class="button-area">
              <a
                href="https://bit.ly/럭스테이앱스토어"
                @click="onClickAppInstall('Appstore')"
                target="_blank"
                v-ga="$analytics.tracking.bind(this, 'home', 'click', 'home_app_store')"
              >
                <button type="button" class="ios">
                  <img src="/img/btn-appstore.png" />
                </button>
              </a>
              <a
                href="https://bit.ly/럭스테이플레이스토어"
                @click="onClickAppInstall('googleplay')"
                target="_blank"
                v-ga="$analytics.tracking.bind(this, 'home', 'click', 'home_google_play')"
              >
                <button type="button" class="android">
                  <img src="/img/btn-googleplay.png" />
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- home-partners-section -->
    <div class="section" id="home-partners-section">
      <div class="container">
        <div class="section-header">
          <div class="title">
            <div class="primary">{{ $__t('Partners') }}</div>
          </div>
        </div>

        <div class="body">
          <ul>
            <li>
              <div class="image">
                <progressive-background :aspect-ratio="0.35" src="/img/partners-logo-kto.png" />
              </div>
            </li>
            <li>
              <div class="image">
                <progressive-background :aspect-ratio="0.35" src="/img/partners-logo-sto.png" />
              </div>
            </li>
            <li>
              <div class="image">
                <progressive-background :aspect-ratio="0.35" src="/img/partners-logo-mss.png" />
              </div>
            </li>
            <li>
              <div class="image">
                <progressive-background :aspect-ratio="0.35" src="/img/partners-logo-sk-telecom.png" />
              </div>
            </li>
            <li>
              <div class="image">
                <progressive-background :aspect-ratio="0.35" src="/img/partners-logo-incheon-airport.png" />
              </div>
            </li>
            <li>
              <div class="image">
                <progressive-background :aspect-ratio="0.35" src="/img/partners-logo-scs.png" />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="section" id="home-host-section" style="background-image: url(/img/home-host-background.jpg)">
      <div class="container">
        <div class="section-header">
          <div class="title">
            <div class="primary">
              {{ $__t('Earn extra money and attract new customers with us.') }}
            </div>
          </div>
        </div>

        <div class="body">
          <p>{{ $__t('별도의 비용없이 호스트가 될 수 있습니다.') }}</p>
          <!-- <ui-solid-button color="primary" size="medium">
            <button @click="onClickHost">{{ $__t('호스트 신청하기') }}</button>
          </ui-solid-button> -->
        </div>
      </div>
    </div>

    <!-- home-host-section -->
    <div class="section" id="home-host-intro-section">
      <div class="container">
        <div class="section-header">
          <div class="title">
            <div class="primary">{{ $__t('Extra space in your store?') }}</div>
          </div>
        </div>

        <div class="section-body">
          <ul>
            <li>
              <div class="image"><img src="/img/host-form-icon-01.png" /></div>
              <div class="text">
                <div class="title">{{ $__t('추가적인 수익창출') }}</div>
                <div class="description">
                  {{ $__t('빈 공간에 대한 보관수수료를 벌 수 있습니다.') }}
                </div>
              </div>
            </li>
            <li>
              <div class="image"><img src="/img/host-form-icon-02.png" /></div>
              <div class="text">
                <div class="title">{{ $__t('고객유입') }}</div>
                <div class="description">
                  {{ $__t('더 많은 고객을 매장으로 유치할 수 있습니다.') }}
                </div>
              </div>
            </li>
            <li>
              <div class="image"><img src="/img/host-form-icon-03.png" /></div>
              <div class="text">
                <div class="title">{{ $__t('홍보 효과') }}</div>
                <div class="description">
                  {{ $__t('매장을 새로운 고객에게 홍보할 수 있는 기회입니다.') }}
                </div>
              </div>
            </li>
            <li>
              <div class="image"><img src="/img/host-form-icon-04.png" /></div>
              <div class="text">
                <div class="title">{{ $__t('무료 가입') }}</div>
                <div class="description">
                  {{ $__t('별도의 가입비나 설치비용이 필요 없습니다.') }}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- home-host-intro-section -->

    <div class="coupon-modal" v-if="isShowCouponModal">
      <div class="coupon-modal-body">
        <div class="modal-close-button">
          <i class="fa fa-times" @click="closeCouponModal"></i>
        </div>
        <div class="modal-title-section" style="padding-top: 0">
          {{ $__t('New coupon issued') }}
        </div>
        <div class="modal-image-section" style="margin-top: 1rem">
          <div class="coupon-left">
            <div class="coupon-image" v-if="selectedCoupon">
              <div class="coupon-image-leftside" :class="{ fix: selectedCoupon.promotion_config.type == 'fix' }"></div>
              <div class="coupon-image-body" :class="{ fix: selectedCoupon.promotion_config.type == 'fix' }">
                <template v-if="selectedCoupon.promotion_config.type == 'fix'">
                  <div class="coupon-unit"></div>
                  <div class="coupon-amount">{{ selectedCoupon.promotion_config.fix_amount.toLocaleString() }}{{ $__t('won') }}</div>
                </template>
                <template v-else>
                  <div class="coupon-rate-amount">{{ selectedCoupon.promotion_config.rate_amount }}%</div>
                </template>
              </div>
              <div style="position: absolute; top: -1rem; right: -1rem">
                <div class="badge circle" style="background-color: red; border-radius: 1.25rem; width: 2.5rem; height: 2.5rem">
                  <div style="width: 3rem; font-size: 0.5rem; top: 0.5rem; left: -0.25rem; font-weight: 600">NEW</div>
                </div>
              </div>
            </div>
            <div class="coupon-image" v-else>
              <div class="coupon-image-leftside" :class="{ fix: true }"></div>
              <div class="coupon-image-body" :class="{ fix: true }">
                <div class="coupon-rate-amount" style="font-size: 1rem">COUPON</div>
              </div>
              <div style="position: absolute; top: -1rem; right: -1rem">
                <div class="badge circle" style="background-color: red; border-radius: 1.25rem; width: 2.5rem; height: 2.5rem">
                  <div style="width: 3rem; font-size: 0.5rem; top: 0.5rem; left: -0.25rem; font-weight: 600">NEW</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--        <div class="modal-title-section">{{selectedCoupon.coupon_name}}</div>-->
        <!--        <div class="modal-exp-date">유효기간  {{!selectedCoupon.coupon_date_due_no_expiration ? $moment(selectedCoupon.coupon_date_due).format('YYYY.MM.DD') : '없음'}}</div>-->

        <div v-if="selectedCoupon" class="modal-description"></div>
        <div
          v-else
          class="modal-description"
          style="font-size: 1.4rem; line-height: 2rem; text-align: center; color: #333333; word-break: normal; word-wrap: break-spaces"
        >
          {{ $__t('Sign in to see your discount offers.') }}
        </div>
        <div class="modal-button">
          <div @click="goToMyCoupons" class="round-button" v-if="selectedCoupon">
            <span>{{ $__t('My Coupons') }}<i class="fa fa-angle-right"></i></span>
          </div>
          <div
            @click="
              isShowCouponModal = !isShowCouponModal;
              $router.push({
                name: 'AuthLogin',
                query: { redirect: $router.currentRoute.fullPath }
              });
            "
            class="round-button"
            v-else
          >
            <span>{{ $__t('로그인') }}<i class="fa fa-angle-right"></i></span>
          </div>
        </div>
      </div>
    </div>

    <div class="store-button-fixed" :class="{ on: isStoreFixedButtonOn }">
      <button type="button" class="search-button" @click="onClickSearchButton">
        {{ $__t('{home}.search-section.button') }}
      </button>
    </div>

    <guide-modal id="luggage-explanation" ref="luggageExplanationModal">
      <template v-slot:body>
        <luggage-explanation />
      </template>
    </guide-modal>

    <guide-modal id="priceGuideModal" ref="priceGuideModal" :headerTitle="$__t('Price Details')">
      <template v-slot:body>
        <price-guide />
      </template>
    </guide-modal>

    <guide-modal id="userGuideModal" ref="userGuideModal" :arrow="true" :noAppear="true" name="userGuideModal">
      <template v-slot:body>
        <user-guide :carousel="true" />
      </template>
    </guide-modal>

    <guide-modal id="priceLuggageModal" ref="priceLuggageModal">
      <template v-slot:body>
        <price />
      </template>
    </guide-modal>
    <ui-slider id="app-install-slider" ref="app-install-slider" :bgClick="false">
      <app-install-slider @cancel="onShowMobileWeb" @close="closeRef('app-install-slider')" />
    </ui-slider>
  </div>
</template>

<script>
import PriceGuide from '@/components/pages/_/PriceGuide';
import UserGuide from '@/components/pages/guide/UserGuide';
import LuggageExplanation from '@/components/pages/luggage/LuggageExplanation';
import Price from '@/components/pages/_/Price';
import LugCarousel from '@/components/modules/LugCarousel/Body';
import LugCarouselItem from '@/components/modules/LugCarousel/Item';
import CarouselCard from '@/components/modules/LugCarouselCard/Body';
import CarouselCardItem from '@/components/modules/LugCarouselCard/Item';
import utils from '@/utils';
import UiSlider from '@/components/modules/Slider/Body.vue';
import AppInstallSlider from '@/components/pages/guide/AppInstallSlider.vue';
import NoticePopup from '@/components/resources/notice/NoticePopup.vue';

export default {
  name: 'DefaultLandingPage',
  components: {
    PriceGuide,
    UserGuide,
    LuggageExplanation,
    Price,
    LugCarousel,
    LugCarouselItem,
    CarouselCard,
    CarouselCardItem,
    AppInstallSlider,
    UiSlider
  },
  data() {
    let userData = this.userData();
    return {
      pageId: 'Home',
      searchOpened: false,
      searchPlaceOpened: false,
      autoComplete: null,
      geocoder: null,
      scrolled: false,
      selectedCity: null,
      predictions: null,
      isShowPopupmenu: false,
      isFocusPopupmenu: false,
      playerVars: {
        autoplay: 1,
        playsinline: 1
      },
      videoIsShow: false,
      videoPlayed: false,
      isTextInputed: false,
      isShowCouponModal: false,
      selectedCoupon: null,
      formData: {
        startDate: this.$moment(userData.date_start).format('YYYY-MM-DD HH:mm'),
        endDate: this.$moment(userData.date_end).format('YYYY-MM-DD HH:mm'),
        quantitySmall: userData.quantity_small,
        quantityBig: userData.quantity_big,
        searchText: userData.search_text
      },
      popupMenu: {
        coordinates: {
          clientX: '',
          clientY: ''
        },
        items: ''
      },

      isStoreFixedButtonOn: false,

      carouselIndex: 0,

      innerWidth: 0,

      scrollDepth: {
        30: false,
        50: false,
        70: false,
        90: false
      }
      // selectedCoupon: {//샘플데이터
      //   "uid": 1,
      //   "user_id": 918,
      //   "coupon_name": "테스트 무료쿠폰",
      //   "coupon_descript": "전체 무료 쿠폰 발급 테스트를 위한 쿠폰 발급 입니다.",
      //   "coupon_code": "COAZ5",
      //   "coupon_date_due": "2021-04-30T01:00:00.000Z",
      //   "coupon_date_due_no_expiration": true,
      //   "promotion_id": 8,
      //   "promotion_config": {
      //     "type": "fix",
      //     "fix_amount": "6"
      //   },
      //   "created_at": "2020-01-08T04:59:48.000Z",
      //   "updated_at": "2020-01-08T04:59:48.000Z",
      //   "used_count": 0,
      //   "expired": false,
      //   "language": "ko",
      //   "coupon_name_locales": {
      //     "base": "테스트 무료쿠폰"
      //   },
      //   "coupon_descript_locales": {
      //     "base": "전체 무료 쿠폰 발급 테스트를 위한 쿠폰 발급 입니다."
      //   },
      //   "used": false
      // },
    };
  },
  computed: {
    auth() {
      return this.$store.state.auth.gid;
    },
    recommendedRegions() {
      return this.$store.state.regions.items;
    },
    priceText() {
      let lang = this.$store.state.lang;

      if (lang === 'ko') {
        return `맡기는 <strong>시간,</strong> 물품의 <strong>크기</strong>에<br /> 따라 <strong>이용하세요</strong>`;
      } else if (lang === 'en') {
        return `<strong>Use our service</strong> by <strong>hours</strong><br/> of storage and <strong>sizes</strong> of your item.`;
      } else {
        return this.$__t('Use our service by hours of storage and sizes of your item.');
      }
    },
    isRouterChanged() {
      return this.$store.state.path.isRouterChanged;
    },
    getReviewImageByViewport() {
      const images = {
        pc: ['/img/home-review-p01.png', '/img/home-review-p02.png', '/img/home-review-p03.png', '/img/home-review-p04.png'],
        mobile: ['/img/home-review-m01.png', '/img/home-review-m02.png', '/img/home-review-m03.png', '/img/home-review-m04.png']
      };

      if (this.innerWidth < 800) {
        return images.mobile;
      } else {
        return images.pc;
      }
    },
    campaignCode() {
      return this.$store.getters['userData/GET_ITEM']({ key: 'campaignCode' });
    },

    promotion() {
      return this.$store.getters['userData/GET_ITEM']({ key: 'promotion' });
    },

    tickets() {
      return this.$store.getters['tickets/GET_ITEMS_BY_LIST']({
        list: 'MyTickets_available'
      });
    },

    ticketsForHost() {
      return this.$store.getters['tickets/GET_ITEMS_BY_LIST']({
        list: 'HostTickets_available'
      });
    },

    notices() {
      return this.$store.getters['resourceNotices/GET_ITEMS_BY_LIST']({
        list: 'Notices'
      });
    },
    coupons() {
      let coupons = this.$store.getters['resourceCoupons/GET_ITEMS_BY_LIST']({
        list: 'MyCoupons'
      });

      if (coupons && coupons.length > 0) {
        coupons = coupons.filter((e) => !e.used && !e.expired);
      }

      return coupons;
    },
    isHeaderCoveredSearchInput() {
      return this.$store.getters['headerCovered/GET_ITEM']();
    },
  },
  watch: {
    $route: {
      handler(to, from) {
        let userData = this.userData();
        if (userData.date_start && userData.date_end) {
          this.formData.startDate = this.$moment(userData.date_start).format('YYYY-MM-DD HH:mm');
          this.formData.endDate = this.$moment(userData.date_end).format('YYYY-MM-DD HH:mm');
          this.formData.quantitySmall = userData.quantity_small;
          this.formData.quantityBig = !userData.quantity_big && !this.formData.quantitySmall ? 1 : userData.quantity_big;
          this.formData.searchText = userData.search_text;
        }
      }
    },
    formData: {
      handler: function () {
        this.$log.log('[Home] handler()', this.formData.startDate, this.formData.endDate);
        let date_start = this.$moment(this.formData.startDate);
        let date_end = this.$moment(this.formData.endDate);
        if (date_start.isSameOrAfter(date_end)) {
          date_end = date_start.add(10, 'minutes');
          this.formData.endDate = date_end.format('YYYY-MM-DD HH:mm');
        }

        this.onChangeFormData();
      },
      deep: true
    },
    isHeaderCoveredSearchInput(value) {
      if (value) {
        this.isStoreFixedButtonOn = true;
      } else {
        this.isStoreFixedButtonOn = false;
      }
    }
  },
  methods: {
    async onClickGoLoginPage() {
      // return this.$router.push({ name: 'AuthLogin' });
      await this.$store.dispatch('auth/redirect', {
        redirect: '/login/existingusers'
      });
    },
    onChangeFormData() {
      var data = {
        date_start: this.$moment(this.formData.startDate).format('YYYY-MM-DD HH:mm'),
        date_end: this.$moment(this.formData.endDate).format('YYYY-MM-DD HH:mm'),
        quantity_small: parseInt(this.formData.quantitySmall),
        quantity_big: parseInt(this.formData.quantityBig),
        search_text: this.formData.searchText
      };

      this.$log.log('[Home] onChangeFormData()', this.formData.startDate, this.formData.endDate);

      this.$store.dispatch('userData/set', data);
    },
    submitForm() {
      this.$analytics.logEvent('home store search button', {
        location: this.formData.searchText,
        'date start': this.formData.startDate,
        'date end': this.formData.endDate,
        small: this.formData.quantitySmall,
        large: this.formData.quantityBig
      });
      this.$analytics.tracking.call(this, 'home_search', 'click', 'home_search_button');
      if (!this.formData.startDate) {
        alert(this.$__t('select drop off time'));
        return;
      } else if (!this.formData.endDate) {
        alert(this.$__t('select pick up time'));
        return;
      }

      this.onChangeFormData();

      if (this.formData.searchText.trim() != '' && this.formData.searchText.trim() != this.$__t('Search current location')) {
        this.$router.push({
          name: 'Stores',
          query: {
            autoSearch: 'text',
            autoSearchText: this.formData.searchText
          }
        });
      } else {
        this.formData.searchText = '';
        this.$router.push({ name: 'Stores', query: { autoSearch: 'my' } });
      }
    },
    showCalendar() {
      $('#site-header').css('z-index', -1);
      $('body').addClass('hide-chat');
    },
    closeCalendar() {
      $('#site-header').css('z-index', 1000);
      $('body').removeClass('hide-chat');
    },
    checkInput(event) {
      if (event.target.value == '') {
        this.clearInput();
      } else {
        this.isTextInputed = true;
        this.excuteAutocompleteService(event.target.value);
      }

      if (event.keyCode === 13) {
        this.submitForm();
      }
    },
    clickCity(city) {
      const lang = this.$store.state.lang;

      this.$analytics.tracking.call(this, 'home_search', 'click', 'home_search_location_keyword');
      this.formData.searchText = this.getRegionsByLang(city);
      this.selectedCity = city;
      this.isShowPopupmenu = true;
    },
    clickPlace(place) {
      const lang = this.$store.state.lang;

      this.$analytics.tracking.call(this, 'home_search', 'click', 'home_search_location_keyword');
      this.formData.searchText = this.getRegionsByLang(place);
    },
    excuteAutocompleteService(text) {
      let service = new google.maps.places.AutocompleteService();
      service.getPlacePredictions({ input: text, componentRestrictions: { country: 'kr' } }, this.displaySuggestions);
    },
    displaySuggestions(predictions, status) {
      if (status != google.maps.places.PlacesServiceStatus.OK) {
        this.$log.log(status);
        return;
      }

      if (predictions) this.predictions = predictions;
    },
    userData() {
      let quantity_small = this.$store.getters['userData/GET_ITEM']({
        key: 'quantity_small'
      });
      let quantity_big = this.$store.getters['userData/GET_ITEM']({
        key: 'quantity_big'
      });
      let date_start = this.$store.getters['userData/GET_ITEM']({
        key: 'date_start'
      })
        ? this.$store.getters['userData/GET_ITEM']({ key: 'date_start' })
        : this.$moment().format('YYYY-MM-DD HH:mm');
      let date_end = this.$store.getters['userData/GET_ITEM']({
        key: 'date_end'
      })
        ? this.$store.getters['userData/GET_ITEM']({ key: 'date_end' })
        : this.$moment().add(10, 'minutes').format('YYYY-MM-DD HH:mm');
      let search_text = this.$store.getters['userData/GET_ITEM']({
        key: 'search_text'
      })
        ? this.$store.getters['userData/GET_ITEM']({ key: 'search_text' })
        : '';

      return {
        quantity_small: quantity_small,
        quantity_big: quantity_big,
        date_start: date_start,
        date_end: date_end,
        search_text: search_text
      };
    },
    loadUserData() {
      let userData = this.userData();
      this.formData.startDate = utils.getClosestValidDateTime(this.$moment(userData.date_start).format('YYYY-MM-DD HH:mm'));
      this.formData.endDate = utils.getClosestValidDateTime(this.$moment(userData.date_end).format('YYYY-MM-DD HH:mm'));
      this.formData.quantitySmall = userData.quantity_small;
      this.formData.quantityBig = userData.quantity_big + userData.quantity_small == 0 ? 1 : userData.quantity_big;
      this.formData.searchText = userData.search_text;
    },
    decreaseBigLuggage() {
      if (this.formData.quantityBig > 0) {
        this.formData.quantityBig -= 1;
      }
    },
    increaseBigLuggage() {
      if (this.formData.quantityBig < 99) this.formData.quantityBig += 1;
    },
    decreaseSmallLuggage() {
      if (this.formData.quantitySmall > 0) this.formData.quantitySmall -= 1;
    },
    increaseSmallLuggage() {
      if (this.formData.quantitySmall < 99) this.formData.quantitySmall += 1;
    },
    clearInput() {
      this.predictions = [];
      this.formData.searchText = '';
      this.isTextInputed = false;
      this.selectedCity = this.recommendedRegions[0];
    },
    searchOnMyLocation() {
      this.$router.push({
        name: 'Stores',
        query: { autoSearch: 'my', t: Date.now() }
      });
    },

    searchOnText(text) {
      this.$router.push({
        name: 'Stores',
        query: { autoSearch: 'text', autoSearchText: text, t: Date.now() }
      });
    },

    quickSearchSlide(direction) {
      if (direction == 'left') {
        $(this.$refs.quickSearchContainer).animate({
          scrollLeft: $(this.$refs.quickSearchContainer).scrollLeft() - $(this.$refs.quickSearchContainer).innerWidth()
        });
      } else if (direction == 'right') {
        $(this.$refs.quickSearchContainer).animate({
          scrollLeft: $(this.$refs.quickSearchContainer).scrollLeft() + $(this.$refs.quickSearchContainer).innerWidth()
        });
      }
    },

    handleScroll() {
      if (!this.$data.scrolled && window.scrollY > 400) {
        this.$data.scrolled = true;
        this.$analytics.tracking.call(this, 'home', 'scroll', 'home_first_scroll');
      }
    },

    scrollTo() {},

    onPlaying() {
      if (!this.$data.videoPlayed) {
        this.$data.videoPlayed = true;
      }
    },

    onClickShowVideo() {
      this.$analytics.tracking.call(this, 'home', 'play', 'home_movie');
      this.$data.videoIsShow = true;
    },

    onClickHost() {
      this.$analytics.tracking.call(this, 'home', 'click', 'home_become_host');
      this.$analytics.logEvent('button for host');
      this.$router.push({ name: 'HostLanding' });
    },

    onInitialize() {
      this.$log.log('onInitialize');

      this.$store
        .dispatch('app/initialize', {})
        .then((data) => {
          if (this.wni.isNative === true) {
            // App VersionCheck
            this.onAppUpdateCheck(data, () => {});
          }
        })
        .catch((e) => {
          this.$log.log(e);
        });
    },

    onAppUpdateCheck(data, handler) {
      this.$log.log('onAppUpdateCheck');

      let { update_status, update_link } = data;

      if (update_status === 'FORCE_UPDATE') {
        this.$store.commit('dialog/ADD_ITEM', {
          message: this.$__t('There is newer version of this application available, click Confirm to upgrade now.'),
          ok: {
            label: this.$__t('Confirm'),
            callback: () => {
              this.wni.execute('wnOpenURL', { url: update_link, exit: true });
            }
          }
        });
      } else if (update_status === 'NEED_UPDATE') {
        this.$store.commit('dialog/ADD_ITEM', {
          message: this.$__t('There is newer version of this application available, click YES to upgrade now?'),
          true: {
            label: this.$__t('예'),
            callback: () => {
              this.wni.execute('wnOpenURL', { url: update_link, exit: false });
            }
          },
          false: {
            label: this.$__t('아니오'),
            callback: () => {
              handler();
            }
          }
        });
      } else {
        handler();
      }
    },
    closeCouponModal() {
      this.isShowCouponModal = false;
    },
    goToMyCoupons() {
      this.closeCouponModal();
      this.$router.push({ name: 'MypageCoupons' });
    },
    searchCurrent() {
      this.formData.searchText = this.$__t('Search current location');
      this.$analytics.tracking.call(this, 'home_search', 'click', 'home_search_location_current');
    },
    openLuggageSelectorPopup(ref, event) {
      let target = $('span[name = "luggage-selector"]');

      this.popupMenu.coordinates.clientX = target.position().left - 625;
      this.popupMenu.coordinates.clientY = target.position().top + 10;

      this.$refs.luggageSelector.openPopup();
    },
    openModal(ref) {
      if (this.$refs[ref]) {
        this.$refs[ref].open(ref);
      }
    },
    closeModal(ref) {
      if (this.$refs[ref]) {
        this.$refs[ref].close();
      }
    },
    openRef(ref) {
      this.$refs[ref].open(ref);
    },
    closeRef(ref) {
      this.$refs[ref].close();
    },
    getRecommendedRegions() {
      return this.$store.dispatch('regions/list');
    },

    getRegionsByLang(item) {
      const lang = this.$store.state.lang;

      switch (lang) {
        case 'ko':
          return item.ko;
          break;
        case 'en':
          return item.en;
          break;
        case 'cn':
          return item.cn;
          break;
        case 'hk':
          return item.hk;
          break;
        case 'jp':
          return item.jp;
          break;
        case 'vn':
          return item.vn || item.en;
          break;
      }
    },

    setCarouselIndex(value) {
      this.carouselIndex = value;
    },

    openUserGuideModal() {
      if (
        !this.$store.getters['userData/GET_ITEM']({
          name: 'userGuideModal',
          noDefault: true
        }) &&
        this.isRouterChanged
      ) {
        this.openModal('userGuideModal');
      }
    },
    openAppInstallSlider() {
      // if (window.sessionStorage.getItem('noSlider')) {
      if (this.$store.getters['layout/GET_ITEM'] === false) {
        return;
      } else {
        this.openModal('app-install-slider');
      }
    },

    setInnerWidth() {
      this.$data.innerWidth = window.innerWidth;
    },

    getCouponsViewed() {
      let couponsViewed = localStorage.getItem('lugstay_coupons_viewed');

      if (!couponsViewed) return [];

      couponsViewed = couponsViewed.split(',');

      return couponsViewed;
    },

    async handleCouponViewed() {
      if (this.coupons.length == 0) return;

      let newCoupon,
        date_now = this.$moment(),
        isBelowWeek,
        couponsViewed;

      newCoupon = this.coupons.reduce((acc, cur) => (this.$moment(acc.created_at) > this.$moment(cur.created_at) ? acc : cur));

      isBelowWeek = this.$moment.duration(date_now.diff(this.$moment(newCoupon.created_at))).asDays() <= 7;

      if (!isBelowWeek) {
        return this.$store.commit('resourceCoupons/SET_VIEWED_LIST_ITEMS', {
          list: 'lugstay_coupons_viewed',
          data: this.coupons
        });
      }

      couponsViewed = this.getCouponsViewed();
      if (couponsViewed.length > 0 && couponsViewed.indexOf(newCoupon.uid.toString()) > -1) return;

      this.$data.selectedCoupon = newCoupon;
      this.$data.isShowCouponModal = true;

      this.$store.commit('resourceCoupons/SET_VIEWED_LIST_ITEMS', {
        list: 'lugstay_coupons_viewed',
        data: this.coupons
      });
    },

    async handleCampaignPromotion() {
      if (this.campaignCode && this.promotion && !this.auth) this.$data.isShowCouponModal = true;

      if (this.auth && this.campaignCode) {
        try {
          let res = await this.axios.post(
            this.$store.state.config.apiURL + '/v2/campaigns/invite/' + this.campaignCode,
            {},
            { headers: this.$store.state.config.userHeaders }
          );

          let { data, error } = res.data;

          if (error) {
            alert(this.$__t(error));
            return;
          }

          let options = {
            list: 'MyCoupons',
            query: {
              page: 1,
              size: 50,
              group: 'available'
            },
            clear: true
          };

          await this.$store.dispatch('resourceCoupons/getList', options);
        } catch (e) {}
      }
    },

    onScrollSearchInputToTop() {
      if (window.innerWidth >= 1120) return;

      const header = $('#site-header');
      const searchInput = $('.search-engine.mobile', this.$el);

      header.offset().top + header.outerHeight() > searchInput.offset().top + searchInput.outerHeight()
        ? this.$store.commit('headerCovered/SET_TRUE')
        : this.$store.commit('headerCovered/SET_FALSE');
    },

    trackScrollDepth(e) {
      setTimeout(() => {
        const winheight = $(window).height();
        const docheight = $(document).height();
        const scrollTop = $(window).scrollTop();
        const trackLength = docheight - winheight;
        const pctScrolled = Math.floor((scrollTop / trackLength) * 100);

        if (pctScrolled === 30) {
          if (this.$data.scrollDepth['30'] === false) {
            this.$analytics.logEvent('scroll', { 'scroll depth': 30 });
          }
          this.$data.scrollDepth['30'] = true;
        } else if (pctScrolled === 50) {
          if (this.$data.scrollDepth['50'] === false) {
            this.$analytics.logEvent('scroll', { 'scroll depth': 50 });
          }
          this.$data.scrollDepth['50'] = true;
        } else if (pctScrolled === 70) {
          if (this.$data.scrollDepth['70'] === false) {
            this.$analytics.logEvent('scroll', { 'scroll depth': 70 });
          }
          this.$data.scrollDepth['70'] = true;
        } else if (pctScrolled === 90) {
          if (this.$data.scrollDepth['90'] === false) {
            this.$analytics.logEvent('scroll', { 'scroll depth': 90 });
          }
          this.$data.scrollDepth['90'] = true;
        }
      }, 0);
    },

    onClickStoresMiddle() {
      this.$analytics.logEvent('bottom button for map');
      this.$router.push({
        name: 'Stores',
        query: { ...this.$route.query },
        params: { ...this.$route.params }
      });
    },

    onClickAppInstall(href) {
      this.$analytics.logTrack('track', 'ButtonforAppInstall');
      this.$analytics.logEvent('button for app install', { 'move to': href });
    },

    onClickSearchButton() {
      this.$analytics.logEvent('main button for map web');
      this.$router.push({
        name: 'Stores',
        query: { ...this.$route.query },
        params: { ...this.$route.params }
      });
    },

    alertAvailableTicket() {
      if (this.tickets.length < 1) return;

      const oldest = this.tickets.reduce((acc, cur) => (acc.updated_at > cur.updated_at ? cur : acc));
      this.$store.commit('alert/ADD_ITEM', {
        message: `${oldest.store_name}에 ${oldest.status === 'ISSUED' ? '예약하신' : '맡기신'} 티켓 ${
          this.tickets.length > 1 ? '외 ' + (this.tickets.length - 1) + '건 추가' : ''
        } 이용중입니다.☝🏻`,
        status: 'info',
        duration: -1,
        onClick: () => {
          if (this.tickets.length === 1) {
            return this.$router.push({
              name: 'TicketSingle',
              params: { id: this.tickets[0].ticket_code }
            });
          }

          return this.$router.push({ name: 'MypageTickets' });
        }
      });
    },

    onShowMobileWeb(e) {
      this.$store.dispatch("custom/checkNoticePopups");
    },
  },

  async created() {
    this.loadUserData();
    this.onInitialize();

    await this.getRecommendedRegions();
    this.$data.selectedCity = this.recommendedRegions[0];
    try {
      if (this.auth > 0) {
        let a = await this.$store.dispatch('auth/authPhoneCheck');
        if (a.is_necessary === true) {
          this.openRef('login-modal');
        }
      }
    } catch (e) {
    } finally {
    }
  },

  mounted() {
    this.setInnerWidth();
    this.onScrollSearchInputToTop();
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('scroll', this.onScrollSearchInputToTop);
    window.addEventListener('scroll', this.trackScrollDepth);
    window.addEventListener('resize', this.setInnerWidth);

    this.$nextTick(() => {
      this.$analytics.logEvent('landing page view');
      setTimeout(() => {
        this.openAppInstallSlider();
        // this.openUserGuideModal();
        this.handleCampaignPromotion();
        this.handleCouponViewed();
        this.alertAvailableTicket();
      }, 1000);
    });
  },

  beforeDestroy() {
    $('#site-header').removeClass('search');
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('scroll', this.onScrollSearchInputToTop);
    window.removeEventListener('scroll', this.trackScrollDepth);
    window.removeEventListener('resize', this.setInnerWidth);
  }
};
</script>
